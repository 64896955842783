<template>
  <div class="container-fluid rounded bg-white p-5">
    <div class="d-flex">
      <p class="my-auto mr-auto">
        <i :class="$route.meta.iconClass"></i>
        <span style="font-size: 22px; color: #7f7f7f" class="font-weight-light">
          Fiche comité :
        </span>
        <span style="font-size: 25px" class="font-weight-bold">{{
          COMITE.name
        }}</span>
      </p>
      <div class="row mb-3">
        <div class="col-12">
          <modal-actions
            mode="show"
            @print="
              $router.push({
                name: 'print-committee',
                params: {
                  id: $route.params.id,
                  slug: $route.params.slug,
                },
              })
            "
            @close="
              $router.go($store.getters['routerHistory/fromCreation'] ? -2 : -1)
            "
          />
        </div>
      </div>
    </div>
    <div class="shdow">
      <div class="row">
        <div class="col-12 d-flex flex-wrap justify-content-between">
          <p class="h4 text-secondary my-auto mr-auto">
            Informations générales
          </p>

          <div>
            <b-button type="submit" v-if="editMode" @click="UpdateComite" pill
              >Confirmer
            </b-button>
            <b-button
              style="
                background-color: rgb(173, 181, 189);
                border-color: rgb(173, 181, 189);
              "
              @click="editMode = !editMode"
              v-if="editMode"
              pill
              >Annuler
            </b-button>
            <a
              v-else-if="isGovernance || isSuper || isAdmin"
              style="font-size: 35px; color: #007bff; cursor: pointer"
              @click.prevent="editMode = !editMode"
              ><i class="mdi mdi-file-document-edit"></i
            ></a>
          </div>
        </div>
      </div>
      <div class="col-12 p-0">
        <hr class="bg-secondary mb-3 mt-1" />
      </div>

      <div class="row">
        <div class="col">
          <div class="row m-0 p-0">
            <div>
              <div
                class="
                  row
                  m-0
                  p-0
                  align-items-center
                  mb-2
                  col-sm-12 col-lg-auto
                "
              >
                <div
                  style="max-width: 150px; min-width: 150px"
                  class="d-flex align-items-center p-0 m-0 mr-2"
                >
                  <span> Libellé : </span>
                </div>
                <div style="flex: 1">
                  <div v-if="editMode">
                    <b-form-input
                      type="text"
                      :value="selectedData.name"
                      v-model="newComitte.libelle"
                      :class="{
                        'is-invalid':
                          $v.newComitte.libelle.$error &&
                          $v.newComitte.libelle.$dirty,
                      }"
                    >
                    </b-form-input>
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newComitte.libelle.$dirty"
                    >
                      {{
                        !$v.newComitte.libelle.required
                          ? "Champ obligatoire"
                          : !$v.newComitte.libelle.minLength
                          ? `Le champ doit contenir au moins ${$v.newComitte.libelle.$params.minLength.min} caractères.`
                          : !$v.newComitte.libelle.maxLength
                          ? `Le champ ne doit pas dépasser ${$v.newComitte.libelle.$params.maxLength.max} caractères`
                          : ""
                      }}
                    </span>
                  </div>

                  <span
                    class="font-weight-bold"
                    style="margin-bottom: 0px"
                    v-else
                    >{{ selectedData.name }}</span
                  >
                </div>
              </div>
              <div
                class="
                  row
                  m-0
                  p-0
                  align-items-center
                  mb-2
                  col-sm-12 col-lg-auto
                "
              >
                <div
                  style="max-width: 150px; min-width: 150px"
                  class="d-flex align-items-center p-0 m-0 mr-2"
                >
                  <span> Responsable : </span>
                </div>
                <div style="flex: 1">
                  <div v-if="editMode">
                    <v-select
                      :options="responsables"
                      placeholder="Responsable"
                      name="responsable"
                      label="text"
                      :reduce="(responsable) => responsable.value"
                      v-model="newComitte.Responsable"
                      style="padding-right: 0 !important"
                      class="font-weight-normal"
                      :class="{
                        'is-invalid form-control p-0':
                          $v.newComitte.Responsable.$error &&
                          $v.newComitte.Responsable.$dirty,
                      }"
                    >
                    </v-select>
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newComitte.Responsable.$dirty"
                    >
                      {{
                        !$v.newComitte.Responsable.required
                          ? "Champ obligatoire"
                          : ""
                      }}
                    </span>
                  </div>

                  <span
                    class="font-weight-bold"
                    style="margin-bottom: 0px"
                    v-else
                    >{{
                      selectedData.responsable
                        ? selectedData.responsable.firstname +
                          " " +
                          selectedData.responsable.lastname
                        : "-"
                    }}</span
                  >
                </div>
              </div>
              <div
                class="
                  row
                  m-0
                  p-0
                  align-items-center
                  mb-2
                  col-sm-12 col-lg-auto
                "
              >
                <div
                  style="max-width: 150px; min-width: 150px"
                  class="d-flex align-items-center p-0 m-0 mr-2"
                >
                  <span> Périodicité : </span>
                </div>
                <div style="flex: 1">
                  <div v-if="editMode">
                    <v-select
                      :options="periodicite"
                      placeholder="Périodicité"
                      name="periodicite"
                      v-model="newComitte.periodicite"
                      class="font-weight-normal"
                      style="padding-right: 0 !important"
                      :class="{
                        'is-invalid form-control p-0':
                          $v.newComitte.periodicite.$error &&
                          $v.newComitte.periodicite.$dirty,
                      }"
                    >
                    </v-select>
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newComitte.periodicite.$dirty"
                    >
                      {{
                        !$v.newComitte.periodicite.required
                          ? "Champ obligatoire"
                          : ""
                      }}
                    </span>
                  </div>

                  <span
                    class="font-weight-bold"
                    style="margin-bottom: 0px"
                    v-else
                    >{{ selectedData.periodicite }}</span
                  >
                </div>
              </div>
              <div
                class="
                  row
                  m-0
                  p-0
                  align-items-center
                  mb-2
                  col-sm-12 col-lg-auto
                "
              >
                <div
                  style="max-width: 150px; min-width: 150px"
                  class="d-flex align-items-center p-0 m-0 mr-2"
                >
                  <span> Statut : </span>
                </div>
                <div style="flex: 1">
                  <div v-if="editMode">
                    <v-select
                      :options="status"
                      placeholder="Statut"
                      name="Status"
                      v-model="newComitte.status"
                      style="padding-right: 0 !important"
                      class="font-weight-normal"
                      :class="{
                        'is-invalid form-control p-0':
                          $v.newComitte.status.$error &&
                          $v.newComitte.status.$dirty,
                      }"
                    >
                    </v-select>
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newComitte.status.$dirty"
                    >
                      {{
                        !$v.newComitte.periodicite.required
                          ? "Champ obligatoire"
                          : ""
                      }}
                    </span>
                  </div>
                  <span
                    class="font-weight-bold"
                    style="margin-bottom: 0px"
                    v-else
                    >{{ selectedData.status }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div style="width: 100%; flex: 1" class="ml-4">
            <span> Description :</span>
            <div class="W-100">
              <br />
              <textarea
                v-if="editMode"
                class="form-control W-100"
                id="exampleFormControlTextarea1"
                rows="3"
                style="width: 90%"
                v-model="newComitte.description"
              ></textarea>
              <span style="margin-bottom: 0px" class="font-weight-bold" v-else>
                {{ selectedData.description }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr style="position: relative; top: 5px" />

    <b-tabs
      :value="$store.state.tabs.activeTab"
      @changed="activeTab = $store.state.tabs.activeTab"
      @activate-tab="
        (newTabIndex) => $store.dispatch('tabs/changeTab', newTabIndex)
      "
      class="tab-solid tab-solid-primary mt-3"
    >
      <b-tab title="Membres permanents">
        <div class="d-flex mb-2">
          <span class="h4 text-secondary mr-auto my-auto"
            >Liste des membres
          </span>

          <div class="d-flex justify-content-end">
            <b-button
              style="background-color: #198ae3; border-color: #198ae3"
              variant="secondary"
              class="align-btn-table-members"
              @click="addContributerInputs"
              :disabled="isDisabled || !(isGovernance || isAdmin || isSuper)"
            >
              <i class="mdi mdi-plus mr-2"></i>
              Ajouter un membre
            </b-button>
          </div>
        </div>
        <div class="col-12 p-0">
          <hr class="mt-1 mb-3 bg-secondary" />
        </div>
        <Members
          :members="members"
          @confirmItem="addContributer"
          :items="items"
          @deleteItem="deleteItem"
          @cancelItem="removeContributerInputs"
        />
      </b-tab>
      <b-tab title="Planning">
        <div class="d-flex mb-2">
          <span class="h4 text-secondary mr-auto my-auto"
            >Liste des plannings
          </span>

          <div class="d-flex justify-content-end">
            <b-button
              class="align-btn-table-date"
              variant="secondary"
              style="background-color: #198ae3; border-color: #198ae3"
              @click="
                (addNewDateModal = !addNewDateModal),
                  $v.$reset(),
                  (selectedSessionId = null)
              "
            >
              <i class="mdi mdi-plus mr-2"></i>
              Ajouter une date
            </b-button>
            <b-button
              class="align-btn-table-date"
              variant="secondary"
              style="background-color: #198ae3; border-color: #198ae3"
              @click="fullcalendrier"
            >
              <i class="mdi mdi-plus mr-2"></i>
              Afficher calendrier
            </b-button>
          </div>
        </div>
        <div class="col-12 p-0">
          <hr class="mt-1 mb-3 bg-secondary" />
        </div>
        <div class="row">
          <div class="col-12">
            <!-- {{ sessions.map((session) => disabledDates(session.date)) }} -->
            <b-table
              :items="sessions"
              :fields="plannings.tableFields"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="loading"
              responsive
              show-empty
              bordered
              striped
            >
              <template #empty>
                <div class="text-center text-gray">Table vide</div>
              </template>
              <template v-slot:cell(inscriptions)="data">
                <div
                  class="text-center"
                  v-if="
                    !data.item.hasOwnProperty('editMode') || !data.item.editMode
                  "
                >
                  <b-badge
                    pill
                    @click="showpop(data.item, data.item.id)"
                    style="
                      background-color: #adb5bd;
                      border-color: #adb5bd;
                      cursor: pointer;
                    "
                    ><span></span>{{ data.value ? data.value.length : 0 }}
                  </b-badge>
                </div>
                <div v-else>
                  <div class="text-center">
                    <b-badge
                      pill
                      @click="showpop(data.item, data.item.id)"
                      style="
                        background-color: #adb5bd;
                        border-color: #adb5bd;
                        cursor: pointer;
                      "
                      ><span></span>{{ data.value ? data.value.length : 0 }}
                    </b-badge>
                  </div>
                </div>
              </template>
              <template v-slot:cell(hourStart)="data">
                <div
                  v-if="
                    !data.item.hasOwnProperty('editMode') || !data.item.editMode
                  "
                >
                  <span>{{
                    data.value
                      ? moment(data.value, "HH:mm:ss").format("HH:MM")
                      : "-"
                  }}</span>
                </div>
                <div v-else>
                  <input
                    type="time"
                    v-model="newPlanning.hourStart"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        $v.newPlanning.hourStart.$error &&
                        $v.newPlanning.hourStart.$dirty,
                    }"
                  />
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newPlanning.hourStart.$dirty"
                  >
                    {{
                      !$v.newPlanning.hourStart.required
                        ? "Champ obligatoire"
                        : !$v.newPlanning.hourStart.between
                        ? "L'heure de début est invalide "
                        : ""
                    }}
                  </span>
                </div>
              </template>
              <template v-slot:cell(date)="data">
                <div
                  v-if="
                    !data.item.hasOwnProperty('editMode') || !data.item.editMode
                  "
                >
                  <b-link
                    :to="{
                      name: 'show-session',
                      params: {
                        slug: data.item.slug || 'abcde',
                        id: data.item.id,
                      },
                    }"
                    >{{
                      data.value
                        ? moment(data.value, "DD/MM/YYYY").format("DD/MM/YYYY")
                        : "-"
                    }}
                  </b-link>
                </div>
                <div class="row col-12 mx-auto p-0" v-else>
                  <date-picker
                    format="DD/MM/YYYY"
                    v-model="newPlanning.date"
                    style="padding-right: 0 !important"
                    :class="{
                      'is-invalid form-control p-0 ':
                        $v.newPlanning.date.$error &&
                        $v.newPlanning.date.$dirty,
                    }"
                  />
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal pt-1"
                    v-if="$v.newPlanning.date.$dirty"
                  >
                    {{
                      !$v.newPlanning.date.required ? "Champ obligatoire" : ""
                    }}
                  </span>
                </div>
              </template>
              <template v-slot:cell(hourEnd)="data">
                <div
                  v-if="
                    !data.item.hasOwnProperty('editMode') || !data.item.editMode
                  "
                >
                  <span>{{
                    data.value
                      ? moment(data.value, "HH:mm:ss").format("HH:MM")
                      : "-"
                  }}</span>
                </div>
                <div v-else>
                  <input
                    type="time"
                    v-model="newPlanning.hourEnd"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        $v.newPlanning.hourEnd.$error &&
                        $v.newPlanning.hourEnd.$dirty,
                    }"
                  />
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newPlanning.hourEnd.$dirty"
                  >
                    {{
                      !$v.newPlanning.hourEnd.required
                        ? "champ obligatoire"
                        : !$v.newPlanning.hourEnd.minValue
                        ? "L'heure de fin doit étre superieur à l'heure de début"
                        : !$v.newPlanning.hourEnd.between
                        ? "L'heure de fin est invalide"
                        : ""
                    }}
                  </span>
                  {{ newPlanning.id }}
                </div>
              </template>
              <template v-slot:cell(status)="data">
                <div
                  v-if="
                    !data.item.hasOwnProperty('editMode') || !data.item.editMode
                  "
                >
                  <span>{{ data.value }}</span>
                </div>
                <div id="add-page" v-else>
                  <div
                    class="font-weight-normal p-0 form-control"
                    style="padding: 0 !important; height: fit-content"
                    :class="{
                      'is-invalid ':
                        $v.newPlanning.status.$error &&
                        $v.newPlanning.status.$dirty,
                    }"
                  >
                    <v-select
                      appendToBody
                      :options="statusSession"
                      placeholder="Statut"
                      name="Status"
                      v-model="newPlanning.status"
                      class="font-weight-normal bg-transparent"
                      :class="{
                        'is-invalid':
                          $v.newPlanning.status.$error &&
                          $v.newPlanning.status.$dirty,
                      }"
                    >
                      <template #no-options> Liste vide </template>
                    </v-select>
                  </div>
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newPlanning.status.$dirty"
                  >
                    {{
                      !$v.newPlanning.status.required ? "Champ obligatoire" : ""
                    }}
                  </span>
                </div>
                <!--
                   :style="`color:${
                    data.item.status == 'Terminé'
                      ? '#0f7316'
                      : data.item.status == 'Rejeté'
                      ? 'red'
                      : data.item.status == 'En attente'
                      ? '#ffc000'
                      : data.item.status == 'En cours'
                      ? '#4287f5'
                      : 'gray'
                  };`"
                 -->
              </template>
              <template v-slot:cell(actions)="data">
                <table-actions
                  :actions="actions"
                  :editMode="data.item.hasOwnProperty('editMode')"
                  @confirmItem="UpdatePlanning(data.item.id)"
                  @editItem="editItem(data.item)"
                  @cancelItem="cancelItem"
                  @deleteItem="deletePlanning(data.item.id)"
                ></table-actions>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right"
            >
            </b-pagination>
          </div>
        </div>
      </b-tab>
    </b-tabs>

    <!-- add new date -->
    <div v-if="addNewDateModal" to="modals-xyz-548">
      <Modal @close="(addNewDateModal = !addNewDateModal), $v.$reset()">
        <div class="row">
          <div
            class="
              col-12
              d-flex
              flex-wrap
              justify-content-between
              align-items-center
            "
          >
            <span style="color: #072648 !important"
              ><i class="mdi mdi-file-tree"></i>
              <span style="font-family: ubuntu-light, sans-serif">
                Ajouter planning du comité :
              </span>
              <span style="font-size: 22px">{{ selectedData.name }}</span>
            </span>
            <b-button
              type="submit"
              @click="addplanning"
              pill
              :disabled="planificationOption === 2 && !selectedDays.length"
              >Confirmer</b-button
            >
            <hr class="w-100" />
          </div>
          <div class="col-12">
            <b-form-group label="" label-cols="0">
              <b-form-select
                v-model="planificationOption"
                :options="[
                  { value: 1, text: 'Planifier une date' },
                  { value: 2, text: 'Planifier une récurrence' },
                ]"
              ></b-form-select>
            </b-form-group>
          </div>

          <div class="col-12">
            <div class="row">
              <div
                v-if="planificationOption === 2"
                class="
                  col-12
                  d-flex
                  align-items-center
                  justify-content-between
                  flex-wrap
                  my-4
                "
              >
                <label class="d-block mb-0">Tous les</label>

                <b-form-checkbox-group
                  id="days-checkboxes"
                  class="d-flex align-items-center"
                  v-model="selectedDays"
                  :options="weekDays"
                  inline
                ></b-form-checkbox-group>
              </div>

              <div class="col-md-6 col-12">
                <b-form-group
                  class="font-weight-bold"
                  label="Date :"
                  label-cols="4"
                >
                  <div class="font-weight-normal">
                    <date-picker
                      format="DD/MM/YYYY"
                      v-if="planificationOption === 1"
                      v-model="newPlanning.date"
                      style="padding-right: 0 !important"
                      :class="{
                        'is-invalid form-control p-0 ':
                          $v.newPlanning.date.$error &&
                          $v.newPlanning.date.$dirty,
                      }"
                      placeholder="Date"
                    />

                    <button
                      v-else
                      class="p-0 border-0 bg-transparent w-100"
                      @click="datePickerFunction"
                    >
                      <date-picker
                        format="w"
                        id="main-date-picker"
                        v-model="newPlanning.date"
                        style="padding-right: 0 !important"
                        :class="{
                          'is-invalid form-control p-0 ':
                            $v.newPlanning.date.$error &&
                            $v.newPlanning.date.$dirty,
                        }"
                        placeholder="Date"
                        type="week"
                      />
                    </button>

                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newPlanning.date.$dirty"
                    >
                      {{
                        !$v.newPlanning.date.required ? "champ obligatoire" : ""
                      }}
                    </span>
                  </div>
                </b-form-group>
              </div>
              <div class="col-md-6 col-12">
                <b-form-group
                  class="font-weight-bold"
                  label="Heure de début:"
                  label-cols="4"
                >
                  <input
                    type="time"
                    v-model="newPlanning.hourStart"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        $v.newPlanning.hourStart.$error &&
                        $v.newPlanning.hourStart.$dirty,
                    }"
                  />
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newPlanning.hourStart.$dirty"
                  >
                    {{
                      !$v.newPlanning.hourStart.required
                        ? "champ obligatoire"
                        : !$v.newPlanning.hourStart.between
                        ? "L'heure de début est invalide "
                        : ""
                    }}
                  </span>
                </b-form-group>
              </div>
              <div class="col-md-6 col-12">
                <b-form-group
                  class="font-weight-bold"
                  label="Statut :"
                  label-cols="4"
                >
                  <b-form-select
                    :options="statusSession"
                    placeholder="Statut"
                    name="Status"
                    v-model="newPlanning.status"
                    class="font-weight-normal"
                    :class="{
                      'is-invalid':
                        $v.newPlanning.status.$error &&
                        $v.newPlanning.status.$dirty,
                    }"
                  ></b-form-select>
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newPlanning.status.$dirty"
                  >
                    {{
                      !$v.newPlanning.status.required ? "champ obligatoire" : ""
                    }}
                  </span>
                </b-form-group>
              </div>
              <div class="col-md-6 col-12">
                <b-form-group
                  class="font-weight-bold"
                  label="Heure de fin:"
                  label-cols="4"
                >
                  <input
                    type="time"
                    v-model="newPlanning.hourEnd"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        $v.newPlanning.hourEnd.$error &&
                        $v.newPlanning.hourEnd.$dirty,
                    }"
                  />
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newPlanning.hourEnd.$dirty"
                  >
                    {{
                      !$v.newPlanning.hourEnd.required
                        ? "champ obligatoire"
                        : !$v.newPlanning.hourEnd.minValue
                        ? "L'heure de fin doit étre superieur à l'heure de début"
                        : !$v.newPlanning.hourEnd.between
                        ? "L'heure de fin est invalide"
                        : ""
                    }}
                  </span>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>

    <!-- Show calendar -->
    <div v-if="showCalendar" to="modal-xyz-500">
      <Modal @close="showCalendar = !showCalendar">
        <!--show-calendar @dateUpdated="updateDate" :highlightedDates="highlightedDates"/-->
        <full-calendar :events="events" />
      </Modal>
    </div>

    <!-- Inscription & Decision -->
    <div v-if="showInscriptionDecision" to="modals-xyz-548">
      <Modal @close="showInscriptionDecision = !showInscriptionDecision">
        <InscriptionDecision
          :inscriptionDecision="inscriptions"
          :comite="comite"
          :comitename="selectedData.name"
          @showpop="showpop"
        />
      </Modal>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/custom/Modal";
import FullCalendar from "@/components/custom/FullCalendar";

// import Multiselect from 'vue-multiselect'
import InscriptionDecision from "./components/InscriptionDecision.vue";
import TableActions from "@/components/custom/TableActions.vue";
import Members from "./components/Members.vue";
import ModalActions from "@/components/custom/ModalActions.vue";

//import ShowCalendar from "./components/ShowCalendar.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import Swal from "sweetalert2";
// import { uniq } from "lodash";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { concat } from "lodash";
import { datePickerFormat } from "@/helpers/DatePickerFormat.js";
import { USER_URL, ORGANISATION_URL } from "@/helpers/constURL";
//import { httpClient } from "@/helpers/services/HttpClient";

export default {
  name: "ShowInitiative",
  components: {
    Modal,
    InscriptionDecision,
    FullCalendar,
    Members,
    TableActions,
    ModalActions,
    // ShowCalendar,
    // Multiselect,
  },
  data: () => ({
    items: [],
    sortBy: "name",
    perPage: 5,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    events: null,
    comite: null,
    loading: true,
    actions: ["edit", "delete"],
    editMode: false,
    members: [],
    tableplannings: null,
    selectedData: [],
    newComitte: {
      libelle: "",
      Responsable: [],
      Periodicite: [],
      description: "",
      periodicite: [],
      status: "",
    },

    plannings: {
      tableItems: [
        {
          date: "10-07-2021",
          start_hour: "08:00",
          end_hour: "18:00",
          status: "Terminé",
          inscription_decision: 7,
          actions: ["edit", "delete"],
        },
      ],
      tableFields: [
        { key: "date", label: "Session", sortable: true },
        { key: "hourStart", label: "Heure de début", sortable: true },
        { key: "hourEnd", label: "Heure de fin", sortable: true },
        { key: "status", label: "Statut session", sortable: true },
        {
          key: "inscriptions",
          label: "Inscription & Décisions",
          thStyle: { textAlign: "center" },
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
    },
    addNewDateModal: false,
    updateNewDateModal: false,
    showCalendar: false,
    activeTab: null,
    showInscriptionDecision: false,
    planificationOption: 1,
    selectedComiteItem: [{ name: "" }],
    selectedDays: [],
    // weekDays: [
    //   { value: 1, text: "Lundi" },
    //   { value: 2, text: "Mardi" },
    //   { value: 3, text: "Mercredi" },
    //   { value: 4, text: "Jeudi" },
    //   { value: 5, text: "Vendredi" },
    // ],
    inscriptionDecision: {
      tableItems: [
        {
          folder_type: "Initiative",
          inscription_date: "10-10-2021",
          delivery: "Dossier X",
          inscription_status: "Soumise",
          decision: "Ajourné",
          actions: ["edit", "delete"],
        },
      ],
      tableFields: [
        { key: "folder_type", label: "Type dossier", sortable: true },
        { key: "label", label: "Libellé", sortable: true },
        { key: "object_passage", label: "Objet passage", sortable: true },
        { key: "presentator", label: "Présentateur", sortable: true },
        { key: "inscription_date", label: "Date inscription", sortable: true },
        { key: "delivery", label: "Livrable", sortable: true },
        {
          key: "inscription_status",
          label: "Statut inscription",
          sortable: true,
        },
        { key: "guests", label: "Invités", sortable: true },
        { key: "decision", label: "Décision", sortable: true },
        {
          key: "decision_comment",
          label: "Commentaire décision",
          sortable: true,
        },
        { key: "actions", label: "", sortable: false },
      ],
    },
    id: "",
    newPlanning: {
      id: "",
      name: "",
      niveau: "",
      date: "",
      hourStart: "",
      hourEnd: "",
      status: "",
    },
    highlightedDates: [],
    inscriptions: [],
    selectedSessionId: null,
  }),
  validations: {
    newComitte: {
      libelle: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(20),
      },
      Responsable: {
        required,
      },
      periodicite: {
        required,
      },
      status: {
        required,
      },
    },
    newPlanning: {
      date: {
        required,
      },

      hourStart: {
        required,
        between(val) {
          if (this.planificationOption === 2) return true;
          else {
            window.console.log(this.selectedSessionId);

            if (this.newPlanning.date) {
              window.console.log(this.sessions.map(({ id }) => id));

              var filtredSessions = this.sessions.filter(
                (session) =>
                  moment(session.date, "DD/MM/YYYY").format("DD/MM/YYYY") ===
                    moment(this.newPlanning.date).format("DD/MM/YYYY") &&
                  (this.selectedSessionId
                    ? this.selectedSessionId != session.id
                    : true)
              );

              window.console.log(filtredSessions);
              window.console.log(filtredSessions.length);
              return filtredSessions.length
                ? // isBetween
                  !filtredSessions.some(
                    (session) =>
                      moment(session.hourStart, "HH:mm").format("HH:mm") <=
                        moment(val, "HH:mm").format("HH:mm") &&
                      moment(session.hourEnd, "HH:mm").format("HH:mm") >=
                        moment(val, "HH:mm").format("HH:mm")
                  )
                : true;
            }
            return true;
          }
        },
      },
      hourEnd: {
        required,
        minValue(val) {
          return this.newPlanning.hourStart && this.newPlanning.hourStart > val
            ? false
            : true;
        },
        between(val) {
          if (this.planificationOption === 2) return true;
          else {
            window.console.log(val);
            if (this.newPlanning.date) {
              var filtredSessions = this.sessions.filter(
                (session) =>
                  moment(session.date, "DD/MM/YYYY").format("DD/MM/YYYY") ===
                    moment(this.newPlanning.date).format("DD/MM/YYYY") &&
                  (this.selectedSessionId
                    ? this.selectedSessionId != session.id
                    : true)
              );
              return filtredSessions.length
                ? // isBetween
                  !filtredSessions.some(
                    (session) =>
                      moment(session.hourStart, "HH:mm").format("HH:mm") <
                        moment(val, "HH:mm").format("HH:mm") &&
                      moment(session.hourEnd, "HH:mm").format("HH:mm") >
                        moment(val, "HH:mm").format("HH:mm")
                  )
                : true;
            }
            return true;
          }
        },
      },
      status: {
        required,
      },
    },
  },
  methods: {
    concat,
    datePickerFormat,
    disabledDates(date) {
      return (
        !(this.newPlanning.hourStart || this.newPlanning.hourEnd) ||
        this.sessions.find(
          (session) =>
            moment(session.date, "DD/MM/YYYY").format("DD/MM/YYYY") ===
              moment(date).format("DD/MM/YYYY") &&
            ((moment(session.hourStart, "HH:mm").format("HH:mm") <=
              moment(this.newPlanning.hourStart, "HH:mm").format("HH:mm") &&
              moment(session.hourEnd, "HH:mm").format("HH:mm") >=
                moment(this.newPlanning.hourStart, "HH:mm").format("HH:mm")) ||
              (moment(session.hourStart, "HH:mm").format("HH:mm") <=
                moment(this.newPlanning.hourEnd, "HH:mm").format("HH:mm") &&
                moment(session.hourEnd, "HH:mm").format("HH:mm") >=
                  moment(this.newPlanning.hourEnd, "HH:mm").format("HH:mm")))
        )
      );
    },
    editItem(item) {
      this.$store
        .dispatch("comite/fetchComite", this.$route.params.id)
        .then(() => {
          this.selectedSessionId = item.id;
          this.newPlanning.date = datePickerFormat(item.date);
          this.newPlanning.status = item.status;
          this.newPlanning.hourEnd = this.gettime(item.hourEnd);
          this.newPlanning.hourStart = this.gettime(item.hourStart);
          this.newPlanning.niveau = item.rangPassage;
          this.selectedData.planning.sessions =
            this.selectedData.planning.sessions.map((contributer) =>
              contributer.id === item.id
                ? { ...contributer, editMode: true, Modify: true }
                : contributer
            );
        });
    },

    cancelItem() {
      // var verify = Object.values(this.newPlanning).some((item) => item === null || item === "");

      if (
        !(
          this.newPlanning.date == "" &&
          this.newPlanning.status == "" &&
          this.newPlanning.hourStart == "" &&
          this.newPlanning.hourEnd == ""
        )
      ) {
        this.newPlanning.date = "";
        this.newPlanning.status = "";
        this.newPlanning.hourEnd = "";
        this.newPlanning.hourStart = "";
      } else {
        this.$store.dispatch("comite/fetchComite", this.$route.params.id);
      }
    },
    fullcalendrier() {
      window.console.log(this.selectedData, "fullcalendrier");
      this.showCalendar = true;
      this.events =
        this.selectedData &&
        this.selectedData.planning &&
        this.selectedData.planning.sessions
          ? this.selectedData.planning.sessions.map((data) => ({
              id: data.id,
              title: data.name,
              start:
                moment(data.date, "DD/MM/YYYY").format("YYYY-MM-DD") +
                "T" +
                data.hourStart,
              end:
                moment(data.date, "DD/MM/YYYY").format("YYYY-MM-DD") +
                "T" +
                data.hourEnd,
            }))
          : [];
    },
    tabaleitem(data) {
      if (data) {
        if (data[0].inscriptions.length > 0) {
          return 1;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.value.push(tag);
    },
    gettime(valeur) {
      return moment(valeur, "HH:mm:ss").format("HH:MM");
    },
    deletePlanning: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          if (this.$store.dispatch("manage/deletesession", id)) {
            this.selectedData.planning.sessions =
              this.selectedData.planning.sessions.filter(
                (item) => item.id !== id
              );
            Swal.fire("Supprimé!", "", "success");
          }
        }
      });
    },
    getPlanning(item) {
      (this.id = item.id), (this.updateNewDateModal = !this.updateNewDateModal);
      this.newPlanning.date = datePickerFormat(item.date);
      this.newPlanning.status = item.status;
      this.newPlanning.hourEnd = this.gettime(item.hourEnd);
      this.newPlanning.hourStart = this.gettime(item.hourStart);
    },
    UpdatePlanning(id) {
      this.$v.newPlanning.$touch();
      if (!this.$v.newPlanning.$invalid) {
        window.console.log(this.newPlanning.hourEnd);
        var planning = {
          id: id,
          name: this.newPlanning.name,
          date: moment(this.newPlanning.date, "YYYY-MM-DD").format(
            "DD/MM/YYYY HH:mm:ss"
          ),
          hourStart: moment(this.newPlanning.hourStart, "HH:mm:ss").format(
            "HH:mm:ss"
          ),
          hourEnd: moment(this.newPlanning.hourEnd, "HH:mm:ss").format(
            "HH:mm:ss"
          ),
          status: this.newPlanning.status,
        };
        Swal.fire("Le planning est mis à jour !", "", "success");
        this.$store.dispatch("manage/updateSession", planning).then(() => {
          this.$store.dispatch("comite/fetchComite", this.$route.params.id);
          this.newPlanning.date = "";
          this.newPlanning.status = "";
          this.newPlanning.hourEnd = "";
          this.newPlanning.hourStart = "";
          this.newPlanning.niveau = "";
          this.members = this.MEMBERS;
        });
      }
    },

    UpdateComite() {
      this.$v.newComitte.$touch();
      if (!this.$v.newComitte.$invalid) {
        var item = {
          id: this.selectedData.id,
          responsable: this.newComitte.Responsable,
          name: this.newComitte.libelle,
          description: this.newComitte.description,
          periodicite: this.newComitte.periodicite,
          status: this.newComitte.status,
        };
        this.editMode = !this.editMode;
        this.$store.dispatch("comite/updateComite", item).then(() => {
          this.$store
            .dispatch("comite/fetchComite", this.$route.params.id)
            .then(() => {
              Swal.fire("La session est mise à jour !", "", "success");
              this.selectedData = {
                ...this.selectedData,
                Responsable: this.RESPONSABLES.find(
                  (plan) => plan["@id"] === this.newComitte.Responsable
                ),
                name: this.newComitte.libelle,
                description: this.newComitte.description,
                periodicite: this.newComitte.periodicite,
                status: this.newComitte.status,
              };
              this.COMITE.name = this.newComitte.libelle;
            });
        });
      }
    },
    addplanning() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.planificationOption == 1) {
          var sessions = {
            planning: this.COMITE.planning["@id"],
            date: moment(this.newPlanning.date, "YYYY-MM-DD").format(
              "DD/MM/YYYY HH:mm:ss"
            ),
            hourStart: this.newPlanning.hourStart + ":00",
            hourEnd: this.newPlanning.hourEnd + ":00",
            status: this.newPlanning.status,
          };
          //  var verify = Object.values(planning).some((item) => item === null || item === "");

          this.addNewDateModal = false;
          this.$store.dispatch("manage/createSession", sessions).then(() => {
            this.$store.dispatch("comite/fetchComite", this.$route.params.id);
            this.members = this.MEMBERS;
            this.newPlanning.date = "";
            this.newPlanning.status = "";
            this.newPlanning.hourEnd = "";
            this.newPlanning.hourStart = "";
            Swal.fire("La session est bien créée !", "", "success");
          });
        } else {
          this.selectedDays.forEach((element) => {
            var day = moment(this.newPlanning.date).weekday(element);
            var sessions = {
              planning: this.COMITE.planning["@id"],
              date: moment(day).format("DD/MM/YYYY HH:mm:ss"),
              hourStart: this.newPlanning.hourStart + ":00",
              hourEnd: this.newPlanning.hourEnd + ":00",
              status: this.newPlanning.status,
            };
            //  var verify = Object.values(planning).some((item) => item === null || item === "");

            this.addNewDateModal = false;
            this.$store.dispatch("manage/createSession", sessions).then(() => {
              this.$store.dispatch("comite/fetchComite", this.$route.params.id);
              Swal.fire("La session est bien créée !", "", "success");

              this.members = this.MEMBERS;
              this.newPlanning.date = "";
              this.newPlanning.status = "";
              this.newPlanning.hourEnd = "";
              this.newPlanning.hourStart = "";
            });
          });
        }
      }
    },
    updateDate: function (month, year) {
      this.month = month + 1;
      this.currentDate = this.months[month] + " " + year;
      this.englishMonth = this.englishMonths[month] + " " + year;
    },

    addMember: function (index, member) {
      this.members.push({
        member: `/api/${USER_URL}/${member.user}`,
        organisation: member.organisation,
      });
    },
    addContributerInputs() {
      if (this.isGovernance || this.isSuper || this.isAdmin) {
        var sata = this.items.find((data) => data.editMode === true);
        if (sata == null) {
          this.items.unshift({ editMode: true });
        }
      }
    },

    addContributer: function (index, contributer) {
      this.$store.dispatch("comite/updateComite", contributer).then(() => {
        Swal.fire("Le Members est bien créé  !", "", "success");
        this.$store.dispatch("comite/fetchMembers", contributer.id);
        this.members = this.MEMBERS;
      });
    },
    toObject(arr) {
      var rv = {};
      for (var i = 0; i < arr.length; ++i) rv[i] = arr[i];
      return rv;
    },
    showpop(data, id) {
      if (data.inscriptions.length) {
        this.showInscriptionDecision = !this.showInscriptionDecision;
        this.inscriptions = id.toString();
        this.comite = data;
      }
    },
    shallowEqual: function (contributor) {
      return contributor.editMode === true ? true : false;
    },
    lenghttable(x) {
      var res = 0;
      for (var j = 0; j < x.length; j++) {
        res = res + x[j];
      }
      return res;
    },
    removeContributerInputs() {
      this.items.splice(0, 1);
    },
    deleteItem(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          var item = this.members.members
            .filter((data) => data.id != id)
            .map((data) => data["@id"]);
          var data = {
            id: this.selectedData.id,
            members: item,
          };
          this.$store.dispatch("comite/updateComite", data).then(() => {
            this.$store.dispatch("comite/fetchMembers", data.id);
            this.members = this.MEMBERS;
          });
          Swal.fire("Supprimé!", "", "success");
        }
      });
    },
    datePickerFunction() {
      if (this.newPlanning.date && this.planificationOption === 2) {
        var datePickerInput = document.getElementById("main-date-picker");
        datePickerInput.firstChild.firstChild.value = `S${parseInt(
          moment(this.newPlanning.date || new Date()).week() + 1
        )} ${moment(this.newPlanning.date || new Date())
          .startOf("week")
          .format("DD/MM/YYYY")} – ${moment(this.newPlanning.date || new Date())
          .endOf("week")
          .format("DD/MM/YYYY")}`;
      }
    },
  },
  watch: {
    COMITE() {
      this.selectedData = this.COMITE;
      // this.selectedData.plannings = this.selectedData.plannings.map((plan) => ({
      //   ...plan,
      //
      //   sessionlenght: plan.sessions[0]
      //     ? plan.sessions.map((sessions) => {
      //         return sessions.inscriptions.length;
      //       })
      //     : 0,
      // }));
      this.newComitte = {
        ...this.COMITE,
        libelle: this.COMITE.name ? this.COMITE.name : "",
        Responsable: this.COMITE.responsable
          ? this.COMITE.responsable["@id"]
          : "",
        Periodicite: this.COMITE.periodicite ? this.COMITE.periodicite : "",
        description: this.COMITE.description ? this.COMITE.description : "",
      };
      var data = [5, 4];
      var f = "";
      for (var i = 0; i < data.length; i++) {
        if (i + 1 != data.length) {
          f = f + data[i] + ",";
        } else {
          f = f + data[i];
        }
      }
    },
    MEMBERS() {
      this.members = this.MEMBERS;
      this.items = this.MEMBERS.members.map((data) => ({
        member: data,
        organisation_attachment: data.team
          ? data.team.organisation
            ? data.team.organisation.libelle
            : "-"
          : "-",
      }));
    },
    "newPlanning.date"() {
      window.console.log(this.newPlanning);
      this.selectedDays = [];
      // if (this.planificationOption !== 1) {
      this.datePickerFunction();
      // }
    },
    planificationOption() {
      this.datePickerFunction();
    },
  },

  updated() {
    this.datePickerFunction();
  },
  created() {
    this.$store
      .dispatch("comite/fetchComite", this.$route.params.id)
      .then(() => {
        this.$store.dispatch("loader/toggleLoading", false);
      });
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("status/fetchComiteStatus");
    this.$store.dispatch("status/fetchPeriodicite");
    this.$store.dispatch("manage/fetchStatut_session");

    this.$store
      .dispatch("comite/fetchMembers", this.$route.params.id)
      .then(() => {
        this.$store
          .dispatch("planning/fetchinscription", this.MEMBERS.id)
          .then(() => (this.loading = false));
      });
  },

  computed: {
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("organisation", ["ORGANISATIONS"]),
    ...mapGetters("comite", ["COMITE"]),
    ...mapGetters("comite", ["MEMBERS"]),
    ...mapGetters("status", ["COMITE_STATUS"]),
    ...mapGetters("status", ["PERIODICITE"]),
    ...mapGetters("planning", ["INSCRIPTION"]),
    ...mapGetters("planning", ["PLANNING"]),
    ...mapGetters("manage", ["STATUS"]),
    ...mapGetters(["isGovernance", "isAdmin", "isSuper"]),

    isDisabled() {
      return !this.RESPONSABLES.filter(
        (responsable) =>
          !this.items.find((item) =>
            item.member ? item.member.id == responsable.id : false
          ) && responsable.organisationRatachement
      ).length;
    },

    rows() {
      // return this.selectedData.plannings.length;
      return this.sessions.length;
    },
    responsables() {
      return this.RESPONSABLES.map((responsable) => ({
        value: `/api/${USER_URL}/${responsable.id}`,
        text: responsable.firstname + " " + responsable.lastname,
      }));
    },
    statusSession() {
      return this.STATUS.map((data) => data.status);
    },
    status() {
      return this.COMITE_STATUS;
    },
    periodicite() {
      return this.PERIODICITE;
    },

    organisations() {
      return this.ORGANISATIONS.map((organisation) => ({
        value: `/api/${ORGANISATION_URL}/${organisation.id}`,
        text: organisation.libelle,
      }));
    },
    sessions() {
      return this.COMITE.planning && this.COMITE.planning.sessions
        ? this.COMITE.planning.sessions
        : [];
    },
    weekDays() {
      return [
        {
          value: 0,
          text: "Lundi",
          disabled: this.newPlanning.date
            ? this.disabledDates(moment(this.newPlanning.date).weekday(0))
            : true,
        },
        {
          value: 1,
          text: "Mardi",
          disabled: this.newPlanning.date
            ? this.disabledDates(moment(this.newPlanning.date).weekday(1))
            : true,
        },
        {
          value: 2,
          text: "Mercredi",
          disabled: this.newPlanning.date
            ? this.disabledDates(moment(this.newPlanning.date).weekday(2))
            : true,
        },
        {
          value: 3,
          text: "Jeudi",
          disabled: this.newPlanning.date
            ? this.disabledDates(moment(this.newPlanning.date).weekday(3))
            : true,
        },
        {
          value: 4,
          text: "Vendredi",
          disabled: this.newPlanning.date
            ? this.disabledDates(moment(this.newPlanning.date).weekday(4))
            : true,
        },
      ];
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
#days-checkboxes > div > label > span {
  margin: 4px 0 0 0 !important;
  display: block !important;
}

@media (min-width: 600px) {
  .align-btn-table {
    position: relative;
    left: -95px;
  }
}
</style>
